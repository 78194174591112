<template>
    <div class>
        <div class="navbar w-full px-2 relative" style="height:48px">
            <div class="h-full flex justify-center items-center font-bold" style="font-size:16px">物流信息</div>
            <div style="height:48px" class="absolute top-0 left-0 flex justify-center items-center pl-2" @click="goBack">
                <img src="../../assets/images/award/back.png" style="width:25px" alt />
            </div>
        </div>
        <div class="content px-2" v-if="selectPrize.luckDrawRecordId">
            <div class="flex justify-between items-center item py-3">
                <div class="flex">
                    <div style="height:90px;width:90px;overflow:hidden;" class="p-2 flex justify-center items-center">
                        <!-- <img src="../../assets/images/award/record2.png" alt /> -->
                        <img :src="selectPrize.prizeUrl" alt />
                    </div>
                    <div class="pl-1 text-left pt-2">
                        <div class="text-base font-bold">抽奖获得{{ selectPrize.prizeName }}</div>
                        <!-- <div class="text-primary">
                            价值
                            <span class="text-lg font-bold">￥9999</span>
                        </div>-->
                        <div class="text-sm text-gray-500">{{ selectPrize.prizeName }}</div>
                    </div>
                </div>
                <div class="flex justify-center items-center">
                    <div v-if="selectPrize.status && selectPrize.status != 3"
                        class="text-xs text-white bg-primary px-2 rounded-sm" style="padding-top:5px;padding-bottom:5px "
                        @click="receiptOfGoodsFunc">确认收货</div>
                </div>
            </div>
            <div v-if="logistics.kdId && logistics.kdId != null">
                <div class="sm:pb-6 message duration-500 py-4">
                    <div class="text-left">订单编号：{{ logistics.orderNum }}</div>
                    <div class="text-left mes_bottom mt-2 duration-500">收货地址：{{ logistics.addressDetail }}</div>
                </div>
                <div class="mt-4 duration-500 timeline">
                    <el-timeline>
                        <el-timeline-item :timestamp="item.AcceptTime" placement="bottom"
                            v-for="item in logistics.infoData.Traces" :key="item.AcceptTime">
                            <div class="flex justify-start duration-500">
                                <div>
                                    <div class="text-left line_content duration-500">{{ item.AcceptStation }}</div>
                                </div>
                            </div>
                        </el-timeline-item>
                        <!-- <el-timeline-item timestamp="2018/4/3" placement="bottom">
                    <div class="flex justify-start duration-500">
                        <div class="ligth_text text-left">【上海市】快件离开【上海三六灶网点】已发往【上海普通转运中心】</div>
                    </div>
                </el-timeline-item>
                <el-timeline-item timestamp="2018/4/2" placement="bottom">
                    <div class="flex justify-start duration-500">
                        <div class="ligth_text text-left">【上海市】快件离开【上海三六灶网点】已发往【上海普通转运中心】</div>
                    </div>
                        </el-timeline-item>-->
                    </el-timeline>
                </div>
            </div>
            <div v-else>
                <div class="text-left my-2 mt-6 sm:text-base">物流信息</div>
                <el-empty :image-size="100"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { CodeToText } from 'element-china-area-data'
export default {
    data() {
        return {
            selectPrize: {},
            receiptOfGoods: false,
            logistics: {
                kdId: null,
                kdNum: "",
                kdType: null,
                maintainId: null,
                cityCode: [],
                address: "",
                addressDetail: "",
                remark: "",
                withShipperCode: "",
                orderNum: "",
                infoData: {
                    DeliveryMan: "",
                    DeliveryManTel: "",
                    EBusinessID: "",
                    Location: "",
                    LogisticCode: "",
                    ShipperCode: "",
                    State: "",
                    StateEx: "",
                    Traces: [],
                }
            },
        };
    },
    created() {
        let selectPrize = JSON.parse(localStorage.getItem("selectPrize"))
        this.selectPrize = Object.assign({}, this.selectPrize, selectPrize)
        console.log(this.selectPrize)
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        // 获取物流 
        getLogistics() {
            this.$request({
                method: "get",
                url: this.$requestPath.logistics,
                params: {
                    maintainId: this.selectPrize.luckDrawRecordId,
                    kdType: 4
                }
            }).then(res => {
                console.log("获取物流信息", res)
                if (res.code == 0) {
                    if (res.data.info) {
                        res.data.infoData = JSON.parse(res.data.info)
                        res.data.infoData.Traces.reverse()
                        console.log(res.data)

                        res.data.addressDetail = this.getCodeToText(res.data.cityCode) + res.data.address
                        Object.assign(this.logistics, res.data)
                    }

                } else {
                    this.$message.error(res.msg);
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        },
        // 地址转换
        getCodeToText(codeStr) {
            let codeArray = codeStr.split(',')
            let area = "";
            switch (codeArray.length) {
                case 1:
                    area += CodeToText[codeArray[0]];
                    break;
                case 2:
                    area += CodeToText[codeArray[0]] + CodeToText[codeArray[1]];
                    break;
                case 3:
                    area +=
                        CodeToText[codeArray[0]] + CodeToText[codeArray[1]] + CodeToText[codeArray[2]];
                    break;
                default:
                    break;
            }
            return area;
        },
        // 确认收货
        receiptOfGoodsFunc() {
            this.$request({
                method: "put",
                url: this.$requestPath.receiptOfGoods,
                data: {
                    luckDrawRecordId: this.selectPrize.luckDrawRecordId,
                }
            }).then(res => {
                console.log("确认收货", res)
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: "确认收货成功" })
                    setTimeout(() => {
                        this.selectPrize.status = 3
                        localStorage.setItem("selectPrize", JSON.stringify(this.selectPrize))
                    }, 1000)
                } else {
                    this.$message.error(res.msg);
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.navbar {
    box-sizing: border-box;
    border-bottom: 0.0625rem solid var(--borderLightColor);
}

.content {
    height: calc(100vh - 3rem);
    overflow-y: scroll;
}

.item,
.message {
    border-bottom: 0.0625rem solid var(--borderLightColor);
}
</style>
<style>
.el-timeline-item__timestamp {
    display: flex;
    justify-content: flex-start;
}

.el-timeline .el-timeline-item:nth-child(1) .el-timeline-item__node {
    background: red;
}
</style>